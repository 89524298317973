import React from 'react';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';

import doctorImage from '../../assets/images/doctor-neutral.png';

const ProviderHeaderInfo = ({provider}) => {
    return (
        <React.Fragment>
            <Col xs="auto">
            <Image style={{height: "250px", width: "200px"}} 
                rounded 
                src={provider.imageUrl ? provider.imageUrl : doctorImage } />
            </Col>
            <Col xs="auto">
                <h4>{provider.fullName}{provider.professionalTitle && `, ${provider.professionalTitle}`}</h4>
                {
                    provider.acceptingNewPatients === "Yes" ? 
                        <h6><Badge variant="primary">Accepting New Patients</Badge></h6> :
                        <h6><Badge variant="warning">Not Accepting New Patients</Badge></h6> 
                }
                {
                    provider.department && (
                        <div>Department: {provider.department}</div>
                    )
                }
                {
                    provider.specializingIn && (
                        <div>Specializing In: {provider.specializingIn}</div>
                    )
                }
                {
                    provider.providerType && (
                        <div>Provider Type: {provider.providerType}</div>
                    )
                }
            </Col>
        </React.Fragment>
    )
}

export default ProviderHeaderInfo;