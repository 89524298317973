import { useState, useEffect } from 'react';

import { searchForProviders, 
         getProviderInformation, 
         getLocationByID, 
         getProviderReport } from '../utils/phynd-api';
import { PhyndSuggestTypes, 
         PhyndFieldNames } from '../utils/constants';

/**
 * Custom hook that will be used to interact with the Phynd API. This will 
 * be used to search using the 'search' and 'suggest' Phynd API endpoints. 
 * This method will take two values, the first one is the search that will
 * be done (i.e. search provider by npi, search for providers by specialties) 
 * and based on this search type the appropriate method will be called.
 */
const usePhyndAPI = (searchType, phyndFieldValue) => {
    
    const [loading, setLoading] = useState(false);
    
    /**
     * This is the field that is used when you are doing a search and multiple
     * providers will be returned by the Phynd API.
     */
    const [providers, setProviders] = useState([]);
   
    /**
     * This field is used when you know that the Phynd API call will only return
     * one provider. 
     */
    const [provider, setProvider] = useState(null);

    /**
     * This field is used when you know that the Phynd API call will only
     * return one Location (i.e. this is done by using the Location ID in Phynd)
     */
    const [location, setLocation] = useState(null);

    /**
     * This field will be used to store the results of the report being requested
     * by the user.
     */
    const [reportData, setReportData] = useState([]);
   
    /**
     * If the query that you send to the Phynd API doesn't return anything then this
     * is how you can check for this and update the UI accordingly. 
     */
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        const getData = async () => {
            if(phyndFieldValue !== null) {
                let results;
                setLoading(true);
                
                switch(searchType) {
                    case 'providerName':
                        results = await getProviderInformation(PhyndFieldNames.DISPLAY_NAME, phyndFieldValue)
                        if(results) {
                            setProvider(results);
                            document.title = "Keck Provider - " + results.fullName;
                        } 
                        break;
                    case 'phyndId':
                        results = await getProviderInformation(PhyndFieldNames.PROVIDER_ID, phyndFieldValue)
                        if(results) {
                            setProvider(results);
                            document.title = "Keck Provider - " + results.fullName;
                        } 
                        break;
                    case 'location':
                        results = await getLocationByID(phyndFieldValue);
                        if(results) {
                            setLocation(results);
                            document.title = "Keck Location - " + results.name;
                        } 
                        break;
                    case 'specialties':
                        results = await searchForProviders(PhyndSuggestTypes.SUGGEST_SPECIALTIES, phyndFieldValue);
                        if(results) {
                            setProviders(results);
                            document.title = "Keck Specialties Search Results - " + phyndFieldValue;
                        } 
                        break;
                    case 'clinicalTerms':
                        results = await searchForProviders(PhyndSuggestTypes.SUGGEST_CLINICAL_TERMS, phyndFieldValue);
                        if(results) {
                            setProviders(results);
                            document.title = "Keck Clinical Term Search Results - " + phyndFieldValue;
                        } 
                        break;
                    case 'reports':
                        results = await getProviderReport(phyndFieldValue);
                        if(results) {
                            setReportData(results);
                            document.title = phyndFieldValue.friendlyName;
                        }
                        break;
                    default:
                        break;
                }

                if(!results) {
                    setNotFound(true);
                }

                setLoading(false);
            }
        };

        getData();

        return () => {
            document.title = "Keck Providers";
        }
    }, [searchType, phyndFieldValue]);

    return {
        loading,
        providers,
        reportData,
        provider,
        location,
        notFound
    }
}

export default usePhyndAPI;