/**
 * A small utility function that will be used to get the clinical keywords for 
 * the provider based on whether or not they have a serviceLine associated with 
 * their account in Phynd.
 */
export const extractClinicalKeywords = (serviceLines) => {
    let results = [];
    
    serviceLines && serviceLines.map(serviceLine => {
        return serviceLine.serviceGroups && serviceLine.serviceGroups.map(serviceGroup => {
            return serviceGroup.clinicalTerms && serviceGroup.clinicalTerms.map(term =>
                results.push({id: term.id, 
                              name: term.name, 
                              serviceGroupName: term.serviceGroupName,
                              status: term.active,
                              createdDate: term.createdDate})
            )
        })
    })

    if(results) {
        // If there are any clinical keywords we'll sort them by alphabetical
        // order before sending the data back to the caller of this function
        let sortedResults = results.sort((a, b) => 
            (a.name > b.name) ? 1 : -1
        )

        return sortedResults;
    } else {
        return [];
    }
}

/**
 * The Phynd API's Suggest endpoint does give us back a Provider's Phynd ID but this
 * ID has some text before (and possibly) after the id. The code in this function cleans
 * out this extra text so that you only get the PhyndID and nothing else.
 */
export const cleanUpPhyndID = (phyndId) => {
    let cleanedUpPhyndId = phyndId.replace('PN207-', '').replace('-ALT', ''); 
    return cleanedUpPhyndId;
}
