import React from 'react';
import { Link } from 'react-router-dom';
import Media from 'react-bootstrap/Media';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';

import genericDoctorImage from '../../assets/images/doctor-neutral.png';

const ProviderCard = ({ provider }) => {
    console.log(provider);
    return (
        <Link to={`/provider/${provider.providerID}`} className="provider-card">
            <Media className="border">
                <Image style={{height: "120px", width: "100px"}} src={provider.imageUrl ? provider.imageUrl : genericDoctorImage } />
                <Media.Body>
                    <h5>{provider.fullName}{provider.title && `, ${provider.title}`}</h5>
                    <Row>
                        <Col>
                            { provider.department && (
                                    <>
                                        <div className="text-muted">Department: {provider.department}</div>
                                        <div className="text-muted">Score: {provider.score}</div>
                                    </>
                                )
                            }
                        </Col>
                        <Col xs="auto">
                        </Col>
                        <Col xs="auto">
                            {
                                provider.acceptingNewPatients === "Yes" ? 
                                    <h6><Badge variant="primary">Accepting New Patients</Badge></h6> :
                                    <h6><Badge variant="warning">Not Accepting New Patients</Badge></h6> 
                            }
                        </Col>
                    </Row>
                </Media.Body>
            </Media>
        </Link>
    )
}

export default ProviderCard;