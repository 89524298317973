import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar'
import { Link } from 'react-router-dom';
import AboutModal from '../AboutModal';

import { authProvider } from '../../utils/authProvider';
import { getUserGroups } from '../../utils/graphService';

const NavBar = () => {
    
    const [showModal, setShowModal] = useState(false);
    const toggleModal = () => {
      setShowModal(showModal => !showModal);
    }

    const logOut = async () => {
      await authProvider.logoutAsync();
    }

    console.log(authProvider);

    if(authProvider.getAccount().idToken.hasgroups) {
      authProvider.getAccessToken().then(response => {
        getUserGroups(response.accessToken);
      });
    }

    return (
      <React.Fragment>
        <AboutModal showModal={showModal} closeModal={toggleModal} />
        <Navbar className="py-1" bg="dark" expand="lg" variant="dark">
            <Navbar.Brand as={Link} to="/">KeckPMD-Dashboard</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                  <NavDropdown title={authProvider.getAccount().name} id="basic-nav-dropdown" >
                    <NavDropdown.Item href="#" onClick={() => logOut()}>Logout</NavDropdown.Item>
                    <NavDropdown.Item href="#" onClick={() => toggleModal()}>About</NavDropdown.Item>
                  </NavDropdown>
              </Nav>
            </Navbar.Collapse>
        </Navbar>
      </React.Fragment>
    )
}

export default NavBar;