import React from 'react';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';

const Reports = (props) => {
    return (
        <>
            <h3>Phynd Reports</h3>
            <p>Below you will find the different reports available. Click on the report to view the details for that data.</p>
            <Table bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Report Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><Link to='/reports/providers-with-images'>Providers with Images</Link></td>
                        <td>Providers that have an image in their profile</td>
                    </tr>
                    <tr>
                        <td><Link to='/reports/no-health-plans'>Providers with No HealthPlans</Link></td>
                        <td>Providers with no Health Plan data in their profile.</td>
                    </tr>
                    <tr>
                        <td><Link to='/reports/providers-with-videos'>Providers with Video</Link></td>
                        <td>List of Providers that have a video on their profile.</td>
                    </tr>
                    <tr>
                        <td><Link to='/reports/providers-with-no-departments'>Providers with no Departments</Link></td>
                        <td>List of Providers don't have a department.</td>
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export default Reports;