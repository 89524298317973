import React, { createRef } from 'react';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import usePhyndAPI from '../../hooks/usePhyndAPI';
import Loading from '../../components/Loading'

import ProviderHeaderInfo from '../../components/ProviderHeaderInfo';
import ProviderBasicInfo from '../../components/ProviderBasicInfo';
import ProviderEducation from '../../components/ProviderEducation';
import ProviderLocations from '../../components/ProviderLocations';
import ProviderIdentifiers from '../../components/ProviderIdentifiers';
import ProviderSpecialties from '../../components/ProviderSpecialties';
import ProviderClinicalKeywords from '../../components/ProviderClinicalKeywords';
import ProviderHealthPlans from '../../components/ProviderHealthPlans';
import ProviderReviews from '../../components/ProviderReviews';
import ProviderGroups from '../../components/ProviderGroups';
import ZocDoc from '../../components/ZocDoc';

/**
 * Show the details of a given provider based on their full name that is passed to this
 * component. The full name will be in the URL (i.e. provider?name=First+Middle+LastName)
 * notice how we have added the '+' as part of the URL. This was done so that it would be 
 * similar to how it was done in the KeckPMD web application.
 */
const Provider = () => {
   
    const { phyndId } = useParams();
    const { loading, notFound, provider } = usePhyndAPI('phyndId', phyndId);
    const zocDocRef = createRef();

    const checkForZocDocIntegration = () => {
        console.log("this will run after the zocdoc widget has been loaded async");
        console.log("content of zoc-doc-info element: ", document.getElementById("zoc-doc-information").innerHTML);
        console.log("zocodc reference: ", zocDocRef.current);
    }

    if(loading) {
        return (
            <Loading message="Retrieving Provider Data ..." />
        )
    } 

    if(notFound) {
        return (
            <React.Fragment>
                <h5>Provider Not Found</h5>
                <p>There was no provider found with the id of {phyndId}.</p>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <Row style={{"marginTop": "20px"}}>
                <Col xs>
                    {
                        console.log(provider)
                    }
                    { provider && 
                        <React.Fragment>
                            <Row>
                                <ProviderHeaderInfo provider={provider} />
                            </Row>
                            <br />
                            <Tabs defaultActiveKey="basic">
                                <Tab eventKey="basic" title="Basic Information">
                                    <ProviderBasicInfo enrollmentDate={provider.enrollmentDate} 
                                                       titles={provider.titles} 
                                                       languages={provider.languages} 
                                                       biography={provider.biography} />
                                </Tab>
                                <Tab eventKey="education" title="Education/Training">
                                    {
                                        provider.education ? (
                                            <ProviderEducation education={provider.education} />
                                        ) : <p>No Education for this provider.</p>
                                    }
                                </Tab>
                                <Tab eventKey="locations" title="Locations">
                                    {
                                        provider.locations ? (
                                            <ProviderLocations locations={provider.locations} />
                                        ) : (
                                            <p>This provider has no locations associated with their profile.</p>
                                        )
                                    }
                                </Tab> 
                                <Tab eventKey="identifiers" title="Identifiers">
                                    <ProviderIdentifiers identifiers={provider.identifiers} />
                                </Tab>
                                <Tab eventKey="specialties" title="Specialties">
                                    { 
                                        provider.marketingSpecialties.length !== 0 ? (
                                           <ProviderSpecialties specialties={provider.marketingSpecialties} /> 
                                        ) : (
                                            <p>No Specialties Associated with this provider.</p>
                                        )
                                    }
                                </Tab>
                                <Tab eventKey="clincal-keywords" title="Clinical Keywords">
                                    <ProviderClinicalKeywords serviceLines={provider.serviceLines} providerName={provider.fullName} />
                                </Tab>
                                <Tab eventKey="healthPlans" title="Health Plans">
                                    {
                                        provider.healthPlans ? (
                                            <ProviderHealthPlans healthPlans={provider.healthPlans} />

                                        ) : (
                                            <p>This Provider has no Health Plans Associated to their profile.</p>
                                        ) 
                                    }
                                </Tab>
                                <Tab eventKey="reviews" title="Reviews">
                                    {
                                        (provider.ratings && provider.ratings.valid) ? 
                                            <ProviderReviews profile={provider.ratings.profile} reviews={provider.ratings.reviews} /> : 
                                            <p>No Ratings have been found for this provider.</p>
                                    }
                                </Tab>
                                <Tab eventKey="misc" title="Misc">
                                    <ProviderGroups providerGroups={provider.providerGroups} />
                                    <ZocDoc ref={zocDocRef} asyncScriptOnLoad={checkForZocDocIntegration} npi={provider.npi} />
                                </Tab>
                            </Tabs>
                        </React.Fragment>
                    }
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default Provider;