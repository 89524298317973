import { MsalAuthProvider, LoginType } from 'react-aad-msal'

/**
 * Configuration file used for authentication with Azure Active directory. Below you will find
 * some notes on what values I added to make this work:
 * 
 * 1) You need to get the tenant id and add it to the auth.authority object below
 * 2) The auth.redirectUri is setup to the current local host that the app is running. 
 *    You'll need to make sure that http://localhost:3000/auth is added to the azure app redirect
 *    URL so that this application works locally while you are developing it. Note: this is the
 *    default URL for React apps, if this has been changes, then you'll need to add the appropriate
 *    value on the Azure App Registration
 * 3) You must create an empty auth.html file and store it in the public directory
 * 4) For the options object below, you'll need to make the loginType equals to LoginType.Redirect or 
 *    else you'll get a nasty error for a second or two before the app actually re-directs without
 *    any issues (the error was due to an incorrect redirect-url being sent along with the request to
 *    authenticate).
 */
const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/a63249ac-3e0b-4a24-9e0c-c90ab9891e30',
        clientId: '6e6cc32b-cb59-4c63-bd50-8840af06cb35',
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    },
};

const authenticationParameters = {
    scopes: [
        'user.read'
    ]
};

const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html'
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);