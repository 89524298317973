import React from 'react';
import Table from 'react-bootstrap/Table';

const ProviderEducation = ({education}) => {
    return (
        <React.Fragment>
            <Table bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Organization</th>
                        <th>Type</th>
                        <th>Title</th>
                        <th>End Year</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        education.map((education, index) => (
                            <tr key={index}>
                                <td>{education.organization}</td>
                                <td>{education.type}</td>
                                <td>{education.degreeTitle}</td>
                                <td>{education.endYear}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default ProviderEducation;