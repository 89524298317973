import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { CSVLink } from 'react-csv';

import { extractClinicalKeywords } from '../../utils/common-functions';

const ProviderClinicalKeywords = ({providerName, serviceLines}) => {
    
    if(!serviceLines) {
        return <p>No Clinical Keywords currently setup for this provider.</p>
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Clinical Keyword'
        },
        {
            dataField: 'serviceGroupName',
            text: 'Service Line'
        },
        {
            dataField: 'createdDate',
            text: 'Date Created'
        }
    ];
    
    let clinicalTerms = extractClinicalKeywords(serviceLines);
    console.log(clinicalTerms);

    return (
        <React.Fragment>
            {
                clinicalTerms.length ? 
                    <p>Below you will find the {clinicalTerms.length} clinical keywords associated with this provider. { ' '} 
                    { <CSVLink filename={`${providerName} - Clinical Keywords`} data={clinicalTerms}>Export to Excel</CSVLink> }</p> 
                    : <p>No Clinical Keywords currently setup for this provider</p>
            }
            {
                clinicalTerms.length !== 0 ? (
                    <BootstrapTable keyField='id' data={clinicalTerms} columns={columns} bootstrap4 hover condensed />
                ) : null
            }
        </React.Fragment>
    )
}

export default ProviderClinicalKeywords;