import React from 'react';

const ProviderBasicInfo = ( {enrollmentDate, titles, languages, biography} ) => {
    return (
        <React.Fragment>
            {
                enrollmentDate && <p>Enrollement Date: {new Date(enrollmentDate).toLocaleDateString()}</p>
            }
            {
                titles && (
                    <React.Fragment>
                        <p>Academic Titles:</p>
                        <ul>
                            { titles.map((title, index) => (
                                <li key={index}>{title.title}</li>
                            ))}
                        </ul>
                    </React.Fragment>
                )
            }
            {
                languages && (
                    <React.Fragment>
                        <p>Languages:</p>
                        <ul>
                            {
                                languages.map((language, index) => (
                                    <li key={index}>{language.name}</li>
                                ))
                            }
                        </ul>
                    </React.Fragment>
                )
            }
            {
                biography && (
                    <React.Fragment>
                        <p>About Statement:</p>
                        <p dangerouslySetInnerHTML={{__html: biography}}></p>
                    </React.Fragment>
                )
            }
        </React.Fragment>
    )
}

export default ProviderBasicInfo;