/**
 * The following endpoints and api keys can be used to connect to the proxy servers
 * without having to have been part of the whitelisted domains that were setup by
 * the techolding team per our request.
 */
export class ProxyEndpoints {
    static DEV_PROXY_URL = "https://dev-providers-proxy.keckmedicine.org"
    static DEV_PROXY_API_KEY = "231bf4a0-f62a-4a3d-bab4-1fec5602a44f"

    static QA_PROXY_URL = "https://qa-providers-proxy.keckmedicine.org"
    static QA_PROXY_API_KEY = "9311ac32-2e4e-410c-b7ba-bc2aec71aece"
    
    static PROD_PROXY_URL = "https://providers-proxy.keckmedicine.org"
    static PROD_PROXY_API_KEY = "1dd1e27b-2e00-4506-968b-3a1eb88fd398"

    static LOCAL_HOST_PROXY_URL = "http://localhost:8001"
    static AZURE_HOST_PROXY_URL = "https://phynd-proxy.azurewebsites.net"
}

/**
 * Constants that are used to identify what was selected field to use when using the 
 * Suggest endpoint of the Phynd API.
 */ 
export class PhyndSuggestTypes {
    static SUGGEST_PROVIDERS = "providerDetails.name.fullName";
    static SUGGEST_CLINICAL_TERMS = "serviceLines.serviceGroups.clinicalTerms.name";
    static SUGGEST_SPECIALTIES = "specialtyDomains.specialties.specialtyFullName";
    static SUGGEST_LOCATIONS = "locations.name";
}

/**
 * This class will contain a list of field names used to retrieve any data using
 * the Phynd API. I am using this to have a central location where all these constants
 * live so that it's easy to make changes later on when developing this application.
 */
export class PhyndFieldNames {
    static PROVIDER_ID = "providerID";
    static PROVIDER_DEPARTMENT = "employment.departmentName";
    static DISPLAY_NAME = "provider.providerDetails.name.fullName";
    static HEALTH_PLANS = "healthPlans";
    static LOCATION_ID = "id";

    /**
     * This checks to see if a provider has any locations associated with their profile
     * in Phynd.
     */
    static LOCATIONS = "locations";
    
    /**
     * This is used to specify when a provider was imported into Phynd using the daily
     * import of our credentialing file.
     */
    static ENROLLMENT_DATE = "enrollments.Date";

    /**
     * This is used to check what providers have a Video URL in their profile.
     */
    static LINKS = "links";

    /**
     * This is used to check what providers have an image in their profile
     */
    static IMAGES = "images";
}

/**
 * This class will be used to store constants that are used inside of the responses we
 * get back from the Phynd API.
 */
export class PhyndConstants {
    static DATA_IMPORT_ENROLLMENT = "Enrolled through Data Import";
}


/**
 * Object that will be used to store both the internal/friendly names of each of the reports that can be 
 * viewed in the application.
 */
export const reportFriendlyNames = {
    'no-lat-lon': {
        'friendlyName': 'No Latitude Or Longitude',
        'phyndFieldName': PhyndFieldNames.LINKS,
        'emptyField': true 
    },
    'providers-with-images': {
        'friendlyName': 'Providers with Images',
        'phyndFieldName': PhyndFieldNames.IMAGES,
        'emptyField': false 
    },
    'providers-with-videos': {
        'friendlyName': 'Providers with Videos',
        'phyndFieldName': PhyndFieldNames.LINKS,
        'emptyField': false
    },
    'no-health-plans': { 
        'friendlyName': 'Providers with No Health Plan',
        'phyndFieldName': PhyndFieldNames.HEALTH_PLANS,
        'emptyField': true
    },
    'providers-with-no-locations': {
        'friendlyName': 'Providers with No Locations',
        'phyndFieldName': PhyndFieldNames.LOCATIONS,
        'emptyField': true
    },
    'providers-with-no-departments': {
        'friendlyName': 'Providers with No Departments',
        'phyndFieldName': PhyndFieldNames.PROVIDER_DEPARTMENT,
        'emptyField': true
    },
}
