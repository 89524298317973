import axios from 'axios';

const graphUrl = "https://graph.microsoft.com/v1.0";

const graphAPI = axios.create({
    baseURL: graphUrl,
});

export const getUserGroups = async (accessToken) => {
    let headers = {
        "Authorization": "Bearer " + accessToken
    };

    // This allows me to only get the GUID of each of the groups that this user
    // is a member of. To read additional group data you'll need to ask for the
    // scope of Directory.Read.All. See following link: 
    // https://blogs.aaddevsup.xyz/2020/08/why-memberof-microsoft-graph-api-returning-null-fields-for-some-attributes/
    await graphAPI.get(`/me/memberOf`, { headers })
            .then(response => {
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
}