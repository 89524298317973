import React from 'react';
import Table from 'react-bootstrap/Table';

const ProviderHealthPlans = ({healthPlans}) => {
    return (
        <React.Fragment>
            <p>Below you will find the list of {healthPlans.length} Health Plans associated with this provider.</p>
            <Table bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Health Plan Name</th>
                        <th>Date Assigned</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        healthPlans.map((healthPlan, index)=> 
                            <tr key={index}>
                                <td>{healthPlan.healthPlanName}</td>
                                <td>{new Date(healthPlan.createdDate).toLocaleDateString()}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default ProviderHealthPlans;