import React from 'react';
import Table from 'react-bootstrap/Table';

const ProviderGroups = ({providerGroups}) => {
    return (
        <React.Fragment>
            {
                providerGroups ? (
                    <React.Fragment>
                        <p>Below you will find the {providerGroups.length} that this provider belongs to.</p>
                        <Table bordered hover size="sm">
                            <thead>
                                <tr>
                                    <th>Group Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    providerGroups.map((group, index) => 
                                        <tr key={index}>
                                            <td>{ group.groupName }</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </React.Fragment>
                ) : null
            }
        </React.Fragment>
    )
}

export default ProviderGroups;