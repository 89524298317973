import React from 'react';
import makeAsyncScriptLoader from "react-async-script";

const zocDocWidgetURL = "https://static.zocdoc.com/widget/zocdoc.widget.js";

/**
 * These are the data attributes that will be added to the script tag that will
 * load the zocdoc.widget.js file. Any data- elements that don't have any data should
 * be setup as seen below.
 */
const zocDocApiOptions = {
    'data-zd-sdk': '',
    'data-zd-token': 255
};

const ZocDoc = ({npi}) => {
    return (
        <>
            <div id="zoc-doc-information" data-zd-npi={npi} data-zd-timesgrid>ZocDoc</div>
        </>
    )
}

export default makeAsyncScriptLoader(zocDocWidgetURL, { attributes: zocDocApiOptions, removeOnUnmount: true})(ZocDoc);
