import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import ProviderCard from '../../components/ProviderCard';
import Loading from '../../components/Loading';
import usePhyndAPI  from '../../hooks/usePhyndAPI';

/**
 * Shows the search results for providers based on the value that was selected from
 * the Autosuggest Widget.
 */
const Search = () => {
    const { search } = useLocation(); 
    const values = queryString.parse(search);
    let searchField = values.searchField;
    let searchTerm = values.searchTerm;

    const { loading, providers } = usePhyndAPI(searchField, searchTerm);

    if(loading) {
        return (
            <Loading message="Searching Providers ..." />
        )
    }

    return (
        <React.Fragment>
            <br />
            <h5>{providers.length} providers found when searching for {`'${searchTerm.replace('Marketing:', '').replace('Credentialed:', '')}'`}</h5>
            <p>loaded in: {providers.timeElapsed} ms</p>
            <br />
            {
                providers.map((provider, index) => {
                    return (
                        <ProviderCard provider={provider} />
                    )
                })
            }
        </React.Fragment>
    );
}

export default Search;
