import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'basiclightbox/dist/basicLightbox.min.css';
import * as serviceWorker from './serviceWorker';

import { AzureAD } from 'react-aad-msal';
import { authProvider } from './utils/authProvider';

import App from './App';
import './index.css';

ReactDOM.render(
     <AzureAD provider={authProvider} forceLogin={true}>
          <App />
     </AzureAD>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
