import React from 'react';
import Table from 'react-bootstrap/Table';

const ProviderLocations = ({locations}) => {
    return (
        <React.Fragment>
            <p>Below you will find the { locations.length } locations that this provider currently has associated on their profile.</p>
            <Table bordered hover size="sm">
                <thead>
                    <tr>
                        <th>Location Name</th>
                        <th>Address</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Zip Code</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        locations.map((location, index) => 
                            <tr key={index}>
                                <td>{location.displayName}</td>
                                <td>{location.address}</td>
                                <td>{location.state}</td>
                                <td>{location.city}</td>
                                <td>{location.zipCode}</td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </React.Fragment>
    )
}

export default ProviderLocations;