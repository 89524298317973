import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import NavBar from './components/NavBar';
import Home from './pages/Home';
import Provider from './pages/Provider';
import Location from './pages/Location';
import Search from './pages/Search';
import Reports from './pages/Reports';
import ReportDetails from './components/ReportDetails';

function App() {

  return (
    <>
      <Router> 
          <NavBar />
          <Container fluid>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route path='/provider/:phyndId'>
              <Provider />
            </Route> 
            <Route path='/location/:id'>
              <Location />
            </Route>
            <Route path='/search'>
              <Search />
            </Route>
            <Route exact path='/reports'>
              <Reports />
            </Route>
            <Route exact path='/reports/:reportName'>
              <ReportDetails />
            </Route>
          </Container>
      </Router>
    </>
  )
}

export default App;
