import React from 'react';

const ProviderIdentifiers = ({identifiers}) => {
    return (
        <React.Fragment>
            <p>Below you will find the identifiers for this provider.</p>
            <ul>
                { identifiers.map((identifier, index) => (
                        <li key={index}>{identifier.type} - {identifier.value}</li>
                    ))
                }
            </ul> 
        </React.Fragment>
    )
}

export default ProviderIdentifiers;
