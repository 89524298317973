import React from 'react';

const ProviderSpecialties = ({specialties}) => {
    return (
        <React.Fragment>
            <p>Below you will find the marketing specialties for this provider.</p> 
            <ul>
                { 
                    specialties[0].specialties.map((specialty, index)=> {
                        return (<li key={index}>{specialty.specialty}</li>)
                    })
                }
            </ul>
       </React.Fragment>
    )
}

export default ProviderSpecialties;