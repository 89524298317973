import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Bar } from 'react-chartjs-2';

/**
 * This component will be used to display provider stats on the home page
 */
const ProviderStats = ({genders, acceptingNewPatients, generalOptions }) => {
    
    const genderData = {
        labels: genders.map(gender => gender.key),
        datasets: [
            {
                label: 'Doctor Count by Gender',
                data: genders.map(gender => gender.docCount)
            }
        ]
    }
  
    const acceptingNewPatientsData = {
        labels: acceptingNewPatients.map(newPatient => newPatient.key),
        datasets: [
            {
                label: "Accepting New Patients Doctor Count",
                data: acceptingNewPatients.map(newPatient => newPatient.docCount)
            }
        ]
    };

    return (
        <React.Fragment>
            <Row>
                <Col xs={4}>
                <Bar data={genderData} options={generalOptions} />
                </Col>
                <Col xs={4}>
                <Bar data={acceptingNewPatientsData} options={generalOptions} />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ProviderStats;
