import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Bar } from 'react-chartjs-2';

const LocationStats = ({locations, generalOptions}) => {

    const locationsData = {
        labels: locations.map(location => location.key),
        datasets: [
            {
                label: 'Doctors by Location',
                data: locations.map(location => location.docCount)
            }
        ]
    }

    return (
        <Row>
            <Col>
              <Bar data={locationsData} options={generalOptions} />
            </Col>
        </Row>
    )
}

export default LocationStats;