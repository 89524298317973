import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AutoSuggest from 'react-autosuggest';
import debounce from 'lodash/debounce';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import theme from './AutoSuggestWidget.module.css';
import { phyndAPI } from '../../utils/phynd-api';
import { PhyndSuggestTypes } from '../../utils/constants';
import { cleanUpPhyndID } from '../../utils/common-functions';

/**
 * This is my implementation of the auto-complete widget that will be used to talk
 * back to the PhyndAPI and display results for the users in a very nicely formatted
 * way.
 */
const AutoSuggestWidget = () => {

  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  let history = useHistory();

  const suggestionsFetchRequest = debounce(async ({value}) => {

      /**
       * Because of the way that the autosuggest widget works, we have to make
       * the call to the PhyndAPI from within this class so that we can manipulate
       * the results that we get back from the Phynd API to fit the requirements for
       * the autosuggest widget.
       */
      const suggestData = {
          "Limit": 5,
          "SearchTerm": `${value}`,
          "Fuzzy": true,
          "Minimal": false,
          "Debug": true,
          "Categories": ["Provider", "Specialty", "ClinicalTerm", "Location", "Synonym"],
          "CategorySubTypeFilters":[
            {
              "category": "Specialty",
              "subType": "Marketing"
            },
            {
              "category": "Specialty",
              "subType": "Credentialed"
            }
          ]
      };

      await phyndAPI.post("/api/suggest", suggestData)
              .then(response => {
                const suggestionData = response.data.resultCategories.map(section => {
                  return {
                    title: section.name,
                    count: section.count,
                    suggestions: section.results.map(data => {
                      return {
                        phyndID: data.suggestion.id,
                        value: data.suggestion.inputValue,
                        sourceReference: data.suggestion.sourceReference,
                        suggestedValue: data.suggestion.suggestedValue,
                        searchAttributeName: data.suggestion.searchAttributeName
                      }
                    }) 
                  }
                });

                // We are doing an extra filtering of the results so that the AutoSuggest plugin doesn't
                // show any additional data (i.e. empty sections that may be confusing to the users). What
                // the following code does below is remove any sections that have no suggestions on it based
                // on a field that gives you the count of results for each section.
                // See Ticket #17 in Github for more information on this.
                let filteredSuggestionData = suggestionData.filter(data => data.count > 0);
                setSuggestions(filteredSuggestionData);
              })
              .catch(error => {
                console.log(error);
              });
  }, 350);

  const suggestionsClearRequested = () => {
      setSuggestions([]);
  }

  const getSuggestionValue = (suggestion) => (
    suggestion.value
  )
  
  const renderSuggestion = (suggestion) => (
    <span>
      {suggestion.value}
    </span>
  )

  const minimunChracterLength = (value) => {
      return value.trim().length > 2;
  }

  const suggestionSelected = (event, {suggestion}) => {

    switch(suggestion.searchAttributeName) {
      case PhyndSuggestTypes.SUGGEST_PROVIDERS:
        let phyndId = cleanUpPhyndID(suggestion.phyndID); 
        history.push(`/provider/${phyndId}`);
        break;
      case PhyndSuggestTypes.SUGGEST_CLINICAL_TERMS:
        history.push(`/search?searchField=clinicalTerms&searchTerm=${suggestion.suggestedValue}`);
        break;
      case PhyndSuggestTypes.SUGGEST_SPECIALTIES:
        history.push(`/search?searchField=specialties&searchTerm=${suggestion.suggestedValue}`);
        break;
      case PhyndSuggestTypes.SUGGEST_LOCATIONS:
        history.push(`/location/${suggestion.sourceReference}`);
        break;
      default:
        break;
    }
  }

  const renderSectionTitle = (section) => {
    /**
     * The following switch statement looks at the title for each of the fields that
     * were searched using the Phynd API's suggest endpoint and just formats them a bit
     * more so that they are more 'user friendly' when using this application.
     */
    switch(section.title) {
      case "location":
        section.title = "Location";
        break;
      case "clinicalTerm":
        section.title = "Condition";
        break;
      case "provider":
        section.title = "Provider Name";
        break;
      case "specialty":
        section.title = "Specialty"
        break;
      case "synonym":
        section.title = "Synonym"
        break;
      default:
        break;
    }
    
    return (
      <strong>{section.title}</strong>
    );
  }
  
  const getSectionSuggestions = (section) => {
    return section.suggestions; 
  }

  let inputProps = {
      placeholder: "Enter Provider Name, Condition or Speciality" ,
      name: "searchTerm",
      id: "searchTerm",
      value: searchTerm,
      autoFocus: true,
      onChange: (event, {newValue}) => {
          setSearchTerm(newValue);
      }
  };
  
  return (
      <React.Fragment>
          <Row className="justify-content-md-center">
              <Col xs lg="6">
                <br /> 
                <Card style={{'marginBottom': "15px", 'backgroundColor': '#e9ecee'}}>
                    <Card.Body>
                        <Card.Title style={{'fontSize': "1.09em"}}>What do you need help with today?</Card.Title>
                        <AutoSuggest
                            theme={theme} 
                            inputProps={inputProps}
                            multiSection={true}
                            renderSectionTitle={renderSectionTitle}
                            getSectionSuggestions={getSectionSuggestions}
                            suggestions={suggestions}
                            onSuggestionsFetchRequested={suggestionsFetchRequest}
                            onSuggestionsClearRequested={suggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            onSuggestionSelected={suggestionSelected}
                            renderSuggestion={renderSuggestion}
                            shouldRenderSuggestions={minimunChracterLength} />
                    </Card.Body>
                </Card>
              </Col>
          </Row>
      </React.Fragment>
  )
};

export default AutoSuggestWidget;
