import React from 'react';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

/**
 * Generic component to show a Loading spinner when data is being retrieved
 * from the Phynd API.
 */
const Loading = ({message = "Loading ..."}) => {
    return (
        <Row className="justify-content-md-center" style={{'marginTop': "10px"}}>
            <Spinner animation="border" variant="primary" /> <span>&nbsp;&nbsp;{message}</span>
        </Row>
    )
}

export default Loading;