import React from 'react';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';

const ProviderReviews = ({profile, reviews}) => {
    return (
        <React.Fragment>
            <p>Provider Ratings for {profile && profile.name}</p>
            <Table bordered hover size="sm">
                <colgroup>
                    <col width="5%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="75%" />
                </colgroup>
                <thead>
                    <tr>
                        <th>rating</th>
                        <th>date created</th>
                        <th>published</th>
                        <th>review body</th>
                    </tr>
                </thead>
                <tbody>
                    { reviews && reviews.map((review) => {
                        return (
                            <tr key={review.reviewId}>
                                <td>{review.rating}</td>
                                <td>{review.formattedCreated}</td>
                                <td>{review.reviewsource}</td>
                                <td>{review.body}</td>
                            </tr>
                        )
                    }) }
                </tbody>
            </Table>
        </React.Fragment>
    );
}

export default ProviderReviews;