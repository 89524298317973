import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import AutoSuggestWidget from '../../components/AutoSuggestWidget';
import Loading from '../../components/Loading';
import ProviderStats from '../../components/ProviderStats';
import LocationStats from '../../components/LocationStats';

import { getGeneralStats }   from '../../utils/phynd-api';
import { PhyndFieldNames } from '../../utils/constants';

/**
 * This is where the users will be able to search for providers and or conditions
 * when they launch this web application.
 */
const Home = () => {
  const [loading, setLoading] = useState(true);

  // The following will be used to store the stats that we get back from
  // the Phynd API which will then be used to generate the chart for each 
  // category
  const [genders, setGenders] = useState([]);
  const [acceptingNewPatients, setAcceptingNewPatients] = useState([]);
  const [providersWithNoDepartment, setProvidersWithNoDepartment] = useState([]);
  const [providersWithNoHealthPlans, setProvidersWithNoHealthPlans] = useState([]);
  const [providersWithVideoURL, setProvidersWithVideoURL] = useState([]);
  const [locations, setLocations] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [serviceLines, setServiceLines] = useState([]); 

  useEffect(() => {
    async function getData() {
        let data = await getGeneralStats();
        setGenders(data.genderData.items);
        setDepartments(data.departmentData.items);
        setServiceLines(data.serviceLinesData.items);
        setAcceptingNewPatients(data.acceptingNewPatients.items);
        setLanguages(data.languageData.items);
        setLocations(data.locations.items);

        setLoading(false);
    };
    getData();
  }, []);

  if(loading) {
    return (
      <Loading message="Loading Data ..." />
    )
  }
  
  // This is used to set up the bar charts to start at zero
  const generalDataOptions = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true
                }
            }
        ]
    }
  }
  
  return (
      <React.Fragment>
        <AutoSuggestWidget />
        <p>Below you will find different stats for the Keck Providers. Click <Link to='/reports'>here</Link> to view reports.</p>
        <Tabs defaultActiveKey="provider-stats">
            <Tab eventKey="provider-stats" title="Provider Stats">
              <p>Below you will find statistics about Providers here at Keck Hospital.</p>
              <ProviderStats 
                genders={genders} 
                acceptingNewPatients={acceptingNewPatients} 
                generalOptions={generalDataOptions} />
            </Tab>
            <Tab eventKey="department-stats" title="Department Stats">
              <p>Department Stats ...</p>
            </Tab>
            <Tab eventKey="location-stats" title="Location Stats">
              <LocationStats locations={locations} />
            </Tab>
            <Tab eventKey="serviceline-stats" title="Service Lines">
              <p>ServiceLine Stats ...</p>
            </Tab>
        </Tabs>
      </React.Fragment>
  )
}

export default Home;