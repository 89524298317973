import React from 'react';
import Loading from '../../components/Loading';
import usePhyndAPI from '../../hooks/usePhyndAPI';

const Location = ({match}) => {
    console.log(match);
    let phyndLocationID = match.params.id;
    const { loading, notFound, location } = usePhyndAPI("location", phyndLocationID);

    if(loading) {
        return (
            <Loading message="Retrieving Locaiton Data ..." />
        )
    } 

    if(notFound) {
        return (
            <React.Fragment>
                <h5>Location Not Found</h5>
                <p>No Location was found with the Location ID of {phyndLocationID}.</p>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <h3>{location && location.name}</h3>
            {console.log(location)}
            {
                //<p dangerouslySetInnerHTML={{__html: location.introduction}}></p>
            }
        </React.Fragment>
    )
}

export default Location;
