import axios from 'axios'
import { ProxyEndpoints, PhyndConstants, PhyndFieldNames } from './constants';

/**
 * This is the axios instance that will be used to connect to the Phynd API. Any
 * common headers/configurations for each request can be made to this object.
 */
export const phyndAPI = axios.create({
    baseURL: ProxyEndpoints.AZURE_HOST_PROXY_URL,
    headers: {
        'Content-Type':'application/json',
    },
});

const testAPI = axios.create({
    baseURL: "https://app-appdev-qa-common-keckpmdapi.azurewebsites.net",
    headers: {
        'Content-Type':'application/json',
    },
});

const testAPI2 = axios.create({
    baseURL: "https://app-appdev-qa-common-keckpmdapi.azurewebsites.net",
    headers: {
        'Content-Type':'application/json',
        'Referer': 'https://keckmedicine.org'
    },
});

const apimAPI = axios.create({
    baseURL: "https://apim-appdev-qa-common.azure-api.net",
    headers: {
        'Content-Type':'application/json',
    },
});

const apimAPI2 = axios.create({
    baseURL: "https://apim-appdev-qa-common.azure-api.net",
    headers: {
        'Content-Type':'application/json',
    },
});

export const testPingDomain = async () => {
    await testAPI.get("/api/test/ping-domain")
                 .then(response => {
                   console.log(response.data);
                 })
                 .catch(error => {
                   console.log(error)
                 });
}

export const testPingDomain2 = async () => {
    await testAPI2.get("/api/test/ping-domain")
                 .then(response => {
                   console.log(response.data);
                 })
                 .catch(error => {
                   console.log(error)
                 });
}

export const testPingDomain3 = async () => {
  await apimAPI2.get("/ping-domain")
               .then(response => {
                 console.log(response.data);
               })
               .catch(error => {
                 console.log(error)
               });
}

export const testReviewProviderTest = async () => {
    await apimAPI.get("/api/review/provider/test")
                 .then(response => {
                   console.log(response.data);
                 })
                 .catch(error => {
                   console.log(error)
                 });
}

/**
 * Search for providers by the given field name. This field name currently can be
 * the speciality and or the condition.
 */
export const searchForProviders = async (fieldName, searchTerm, start = 0, limit = 100) => {
  const data = {
      "Debug": true,
      "Profile": "default",
      "Start": start,
      "Limit": limit,
      "ResultScope": "Enrolled",
      "SearchParams": [
        {
            "Field": `${fieldName}`,
            "Weight": 1,
            "Value": [`${searchTerm}`],
            "Operation": "Exact",
            "FuzzyEnabled": false
        }
      ],
      "CategorySubTypeFilters":[
        {
          "category": "Specialty",
          "subType": "Marketing"
        }
      ],
      "Sort": "Score",
      "BoostParams": [
        {
          "Field": "group.code",
          "Operation": "Exact",
          "Value": ["featuredproviders"],
          "Weight": 2
        }
      ],
      "ReturnParams": [
        "ProviderDetails",
        "employment",
        "specialtyDomains",
        "group",
        "images"
      ]
    };

    let clinicalSearchResults;
    const timeRequestInitiatated = performance.now();

    await phyndAPI.post("/api/search", data)
            .then(response => {
              clinicalSearchResults = response.data.results.map(item => {
                return {
                  id: item.provider.id,
                  providerID: item.provider.providerID,
                  score: item.score,
                  npi: item.provider.npi,
                  fullName: item.provider.providerDetails.name.fullName,
                  acceptingNewPatients: item.provider.providerDetails.acceptingNewPatients,
                  title: item.provider.providerDetails.professionalTitle,
                  department: item.provider.employment ? item.provider.employment.departmentName : null,
                  gender: item.provider.providerDetails.gender,
                  serviceLines: item.provider.serviceLines ?  item.provider.serviceLines : null,
                  imageUrl:  (item.provider.images && item.provider.images[0].items) && item.provider.images.filter(image => image.name === "Default")[0].items[0].imageUrl,
                  specialties: item.provider.specialtyDomains.map(specialty => {
                      return {
                        name: specialty.name,
                        speciality: specialty.specialties[0].specialty,
                      }
                  }) 
                }
              })
            })
            .catch(error => {
              console.log(error);
            });

    const timeRequestCompleted = performance.now();
    clinicalSearchResults.timeElapsed =(timeRequestCompleted - timeRequestInitiatated).toFixed(2);
    
    return clinicalSearchResults;
}

/**
 * Searches the location endpoint to get the data for the given location based on the
 * Phynd Location ID assocated with the location.
 */
export const getLocationByID = async (locationID) => {
  
  const jsonBody = {
    "Debug": false,
    "Start": 0,
    "Limit": 1,
    "SearchParams": [
        {
            "Field": PhyndFieldNames.LOCATION_ID,
            "Value": [`${locationID}`],
            "Operation": "MatchAnd"
        }
    ]
  };


  return await phyndAPI.post("/api/search/location", jsonBody)
          .then(response => {
            return response.data.locations[0];
          })
          .catch(error => {
            console.log(error);
          });

}

export const getProviderInformation = async (fieldName, fieldValue) => {
  
  let providerResults = null;

  const data = {
    "Debug": false,
    "Start": 0,
    "Limit": 1,
    "ResultScope": "Enrolled",
    "SearchParams": [
        {
            "Field": fieldName,
            "Weight": 1,
            "Value": [`${fieldValue}`],
            "Operation": "Exact",
            "Highlight": true,
            "FuzzyEnabled": false
        }
      ],
    "CategorySubTypeFilters":[
      {
        "category": "Specialty",
        "name": "Marketing"
      }
    ],
    "BoostParams": [
      {
        "Field": "identifier.type",
        "Operation": "exact",
        "Weight": 0,
        "mustNot": true,
        "value": ["EMR ID"],
        "ChildFilter": true
      },
      {

        "Field": "locations.isSchedulable",
        "Operation": "exact",
        "Weight": 0,
        "Value": [true],
        "ChildFilter": true
      }
    ],
    "ReturnParams": [
      "providerDetails", 
      "serviceLines.serviceGroups.clinicalTerms", 
      "identifier", 
      "languages",
      "background",
      "images",
      "specialtyDomains",
      "employment",
      "titles",
      "training",
      "enrollments",
      "locations",
      "healthplans",
      "group"
     ]
    };
    
    const timeRequestInitiatated = performance.now();
    await phyndAPI.post("/api/search", data)
            .then(response => {

              if(response.data === null) {
                return;
              }

              // because we are only getting one provider, we are using the 0-based
              // index to get the details of the provider
              let provider = response.data.results[0].provider;
              
              providerResults = {
                providerID: provider.providerID,
                providerType: provider.providerDetails.providerType && provider.providerDetails.providerType,
                createdDate: provider.createdDate,
                enrollmentDate: provider.enrollments && provider.enrollments.filter(enrollment => {
                  return enrollment.method === 'Enrolled through Data Import'
                })[0].date,
                modifiedDate: provider.modifiedDate,
                acceptingNewPatients: provider.providerDetails.acceptingNewPatients,
                npi: provider.npi,
                noNPI: provider.noNPI,
                firstName: provider.firstName,
                lastName: provider.lastName,
                fullName: provider.providerDetails.name.fullName,
                suffix: provider.providerDetails.suffix && provider.providerDetails.suffix,
                professionalTitle: provider.providerDetails.professionalTitle,
                gender: provider.providerDetails.gender,
                dateOfBith: provider.providerDetails.birthDate && provider.providerDetails.birthDate,
                department: provider.employment ? provider.employment.departmentName : "No Department Setup for this provider",
                serviceLines: provider.serviceLines ? provider.serviceLines.flat() : null,
                identifiers: provider.identifier.map(item => {
                  return {
                    id: item.id,
                    value: item.value,
                    type: item.type,
                    createdDate: item.createdDate,
                    modifiedDate: item.modifiedDate
                  }
                }),
                languages: provider.languages.map(language => {
                  return {
                    id: language.id,
                    name: language.name,
                    proficiency: language.proficiency
                  }
                }),
                titles: provider.titles && provider.titles.map(title => {
                  return {
                    title: title.title
                  }
                }),
                education: provider.training && provider.training.map(education => {
                  return {
                    endYear: education.endYear,
                    type: education.trainingType,
                    degreeTitle: education.degreeTitle,
                    organization: education.organization
                  }
                }),
                healthPlans: provider.healthPlans && provider.healthPlans.map(healthPlan => {
                  return {
                    createdDate: healthPlan.createdDate,
                    healthPlanName: healthPlan.healthPlanName
                  }
                }),
                providerGroups: provider.group && provider.group.map(providerGroup => {
                  return {
                    groupdID: providerGroup.id,
                    groupExternalCode: providerGroup.code,
                    groupName: providerGroup.name
                  }
                }),
                locations: provider.locations && provider.locations.filter(location => location.isSchedulable).map(location => {
                  return {
                    locationId: location.id,
                    displayName: location.displayName,
                    address: location.address.address1,
                    city: location.address.city,
                    state: location.address.state,
                    zipCode: location.address.postalCode,
                    geoLocation: location.address.geoLocation && {
                        lat: location.address.geoLocation.lat,
                        lon: location.address.geoLocation.lon
                    },
                    scheduable: location.isSchedulable
                  }
                }),
                photoUrl: provider.background.photoUrl && provider.background.photoUrl[0],
                videoUrl: provider.background.videoUrl && provider.background.videoUrl[0],
                imageUrl:  (provider.images && provider.images[0].items) && provider.images.filter(image => image.name === "Default")[0].items[0].imageUrl,
                biography: provider.background.biography && provider.background.biography,
                specializingIn: provider.background.areaOfInterest && provider.background.areaOfInterest,
                marketingSpecialties: provider.specialtyDomains && provider.specialtyDomains.filter(specialty => specialty.name === "Marketing").map(specialty => {
                  return {
                    specialties: specialty.specialties
                  }
                })
              }
            })
            .catch(error => {
              console.log(error);
            });

    if(providerResults.noNPI) {
      providerResults.ratings = await getProviderRatings(providerResults.npi);
    }

    const timeEnded = performance.now();
    providerResults.timeElapsed = (timeEnded - timeRequestInitiatated).toFixed(2);

    return providerResults;
}

const getProviderRatings = async (npi) => {

  let reviewData;

  // The following url is using the api management url that David setup to get the NCR Revies
  // and cache them so that the next time they are requested, they are retireved from cache.
  //let apiManagementURL = `https://apim-appdev-qa-common.azure-api.net/api/review/provider/${npi}/200`

  //await phyndAPI.get(apiManagementURL)
  await phyndAPI.get(`/widget/api/org-profile/keckmedicine/npi/${npi}/200?pretty`)
                .then(response => {
                  reviewData = response.data;
                })
                .catch(error => {
                  console.error(error);
                });

  return reviewData;
}

/**
 * Gets the list of providers that were imported by a given date 
 */
export const getProvidersByImportedDate = async (date, dateComparisonOperation, limit = 100) => {
  
  const jsonBody = {
    InheritDefaultProfile: false,
    Start: 0,
    Limit: limit,
    ResultScope: 'Enrolled',
    SearchParams: [
      {
        "Field":"enrollments.Date",
        "Weight": 1,
        "Value": [date],
        "Operation": dateComparisonOperation,
        "FuzzyEnabled": false,
        "Required": true    	
      },
      {
        "Field":"enrollments.method",
        "Weight": 1,
        "Value": [PhyndConstants.DATA_IMPORT_ENROLLMENT],
        "Operation": "Exact",
        "FuzzyEnabled": false,
        "Required": true
      }
    ],
    ReturnParams: [
      "providerDetails",
      "enrollments"
    ]
  };

  let providerResults;
  
  await phyndAPI.post("/api/search", jsonBody)
          .then(response => {
            providerResults = response.data.results.map(item => {
              return {
                id: item.provider.id,
                createdDate: item.provider.createdDate,
                enrollmentDate: item.provider.enrollments && item.provider.enrollments[0].date,
                modifiedDate: item.provider.modifiedDate,
                npi: item.provider.npi,
                firstName: item.provider.firstName,
                lastName: item.provider.lastName,
                fullName: item.provider.providerDetails.name.displayFullName
              }
            })
          })
          .catch(error => {
            console.log(error);
          });
  
          return providerResults;

}

export const getProvidersWithDataInField = async (fieldName, limit = 100) => {
  const jsonBody = {
      InheritDefaultProfile: false,
      Start: 0,
      Limit: 1000,
      SearchParams: [
        {
          "Field": fieldName,
          "Operation": "Exists",
        }
      ],
      ReturnParams: [
        "providerDetails",
        "links"
      ]
  };

  let providerResults;
  await phyndAPI.post("/api/search", jsonBody)
          .then(response => {
            providerResults = response.data.results.map(item => {
              return {
                id: item.provider.id,
                providerID: item.provider.providerID,
                npi: item.provider.npi,
                createdDate: item.provider.createdDate,
                enrollmentDate: item.provider.enrollments && item.provider.enrollments[0].date,
                modifiedDate: item.provider.modifiedDate,
                firstName: item.provider.firstName,
                lastName: item.provider.lastName,
                fullName: item.provider.providerDetails.name.displayFullName,
                videoUrl: item.provider.links ? item.provider.links[0].url: null
              }
            })
          })
          .catch(error => { console.log(error);
          });

  return providerResults;

}

/**
 * This method is used to get the requested provider preport.
 */
export const getProviderReport = async (reportInfo, limit = 1000) => {

  console.log("reportInfo", reportInfo);

  // For performance reasons, the return params is limited to only the basic data
  // that we want to show to the users when they click on the reports link. Also,
  // by default all results will be sorted by the last name of the provider.
  const jsonBody = {
      InheritDefaultProfile: false,
      Start: 0,
      Limit: limit,
      ResultScope: 'Enrolled',
      ReturnParams: [
        "providerDetails",
        "employment",
        "enrollments"
      ],
      Sort: "Field",
      SortFields: [
        {
          Field: "providerDetails.name.last",
          Order: "asc"
        }
      ]
  }

  if(reportInfo.emptyField) {
    jsonBody.ExclusionParams = [
      {
        "Field": reportInfo.phyndFieldName,
        "Operation": "Exists"
      }
    ]
  } else {
    jsonBody.SearchParams = [
      {
        "Field": reportInfo.phyndFieldName,
        "Operation": "Exists",
      }

    ]
  }

  let providerResults;
  await phyndAPI.post("/api/search", jsonBody)
          .then(response => {
            providerResults = response.data.results.map(item => {
              return {
                id: item.provider.id,
                providerID: item.provider.providerID,
                modifiedDate: item.provider.modifiedDate,
                npi: item.provider.npi,
                department: item.provider.employment ? item.provider.employment.departmentName : null,
                firstName: item.provider.firstName,
                lastName: item.provider.lastName,
                fullName: item.provider.providerDetails.name.displayFullName
              }
            })
          })
          .catch(error => {
            console.log(error);
          });
  return providerResults;

}

/**
 * This method can be used to get a list of providers with no data in the
 * specified field name.
 */
export const getProvidersWithBlankField = async (fieldName, limit = 100) => {

  const jsonBody = {
      InheritDefaultProfile: false,
      Start: 0,
      Limit: limit,
      ResultScope: 'Enrolled',
      ExclusionParams: [
        {
          "Field": fieldName,
          "Operation": "Exists"
        }
      ],
      ReturnParams: [
        "providerDetails",
        "employment",
        "enrollments"
      ]
  }

  let providerResults;
  await phyndAPI.post("/api/search", jsonBody)
          .then(response => {
            providerResults = response.data.results.map(item => {
              return {
                id: item.provider.id,
                providerID: item.provider.providerID,
                createdDate: item.provider.createdDate,
                enrollmentDate: item.provider.enrollments && item.provider.enrollments[0].date,
                modifiedDate: item.provider.modifiedDate,
                npi: item.provider.npi,
                firstName: item.provider.firstName,
                lastName: item.provider.lastName,
                fullName: item.provider.providerDetails.name.displayFullName
              }
            })
          })
          .catch(error => {
            console.log(error);
          });
  return providerResults;
}

export const getStatsForGivenField = async () => {
  
  let facetParameters = [];
  let exclusionParameters = [];

  let facetParam = {
    Name: "healthPlanData",
    Field: "providerDetails"
  };

  let exclusionParam = {
      Field: 'providerDetails.healthPlans',
      Operation: 'Exists'
  };

  /*{
    "Field": fieldName,
    "Operation": "Exists"
  }*/

  facetParameters.push(facetParam);
  exclusionParameters.push(exclusionParam);

  const requestBody = {
      InheritDefaultProfile: false,
      Start: 0,
      Limit: 0,
      ResultScope: 'Enrolled',
      //FacetParams: facetParameters,
      ExclusionParams: exclusionParameters
  }

  let results;
  await phyndAPI.post("/api/search", requestBody)
              .then(response => {
                  results = response.data.aggregation;
              })
              .catch(error => {
                console.log(error);
              });
  
  return results;
}

/**
 * The following function will return General Stats for the providers currently
 * stored/managed in Phynd. We are using FacetParameters when sending request for
 * this data to the Phynd API.
 */
export const getGeneralStats = async () => {
  // We are using the FacetParams parameter per Phynd's documentation
  // this is what can be used to get some numbers back on the providers
  // currently being hosted on Phynd. It's important that you get the
  // correct field name because if you won't no data will be returned back
  // from the returned result. The name of each returned data is something
  // that you can make up on your own, but to keep this simple and easy to
  // refer to we are using the [fieldName]Data format for each field that
  // we can refer to when we get the data back.  One last note, the data
  // that is retrieved will be in the 'aggregation' field of the returned
  // json result from Phynd's API. The ExclusionParam parameter is used so
  // that we only get results for providers that are currently visible in the
  // find-a-provider page (i.e. providers.keckmedicine.org).
  const jsonBody = {
      InheritDefaultProfile: false,
      Start: 0,
      Limit: 0,
      ResultScope: 'Enrolled',
      FacetParams: [
        {
          Name: 'departmentData',
          Field: 'employment.departmentName',
        },
        {
            Name: 'serviceLinesData',
            Field: 'serviceLines.name'
        },
        {
            Name: 'categoryTypeData',
            Field: 'providerDetails.providerCategoryTypeName'
        },
        {
          Name: 'locationsData',
          Field: 'providerDetails.locations'
        },
        {
          Name: 'createdDateData',
          Field: 'providerDetails'
        },
        {
          Name: 'genderData',
          Field: 'providerDetails.gender'
        },
        {
          Name: 'languageData',
          Field: 'language.name'
        },
        {
          Name: 'acceptingNewPatients',
          Field: 'providerDetails.acceptingNewPatients'
        },
        {
          Name: 'locations',
          Field: 'location.name'
        }
      ],
      ExclusionParams: [
        {
          Field: 'providerDetails.status',
          Weight: 1,
          Value: ['Inactive'],
          Operation: 'Exact',
          FuzzyEnabled: true,
        }
      ],
  };

  let results;

  await phyndAPI.post("/api/search", jsonBody)
              .then(response => {
                  results = response.data.aggregation;
              })
              .catch(error => {
                console.log(error);
              });
  
  return results;
}
