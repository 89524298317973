import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

/**
 * A simple Modal that will show some basic information about this application.
 */
const AboutModal = ({showModal, closeModal}) => {
    return (
        <Modal show={showModal} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>About KeckPMD-Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>An application used to search for Providers and Specialities at Keck Hospital.</p>
                <p>Version 1.0</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={closeModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AboutModal;