import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';


import Loading from '../Loading';
import { reportFriendlyNames } from '../../utils/constants';
import usePhyndAPI from '../../hooks/usePhyndAPI';

const ReportDetails = () => {
    
    const { reportName } = useParams();
    console.log("reportName", reportName);
    let reportInformation = reportFriendlyNames[reportName];

    const { loading, reportData } = usePhyndAPI('reports', reportInformation);

    if(!reportInformation) {
        return (
            <h3>Report Not found.</h3>
        )
    }

    if(loading) {
        return (
            <Loading message="Generating Report ..." />
        )
    }

    console.log(reportData);

    return (
        <>
            <br />
            <h3>{` ${reportData.length} ${reportInformation.friendlyName}`}</h3>
            <br />
            <Table bordered hover size="sm">
                <thead>
                    <tr>
                        <th>NPI</th>
                        <th>Name</th>
                        <th>Department</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reportData.map((provider, index) => 
                            <tr key={index}>
                                <td><Link to={`/provider/${provider.providerID}`}>{provider.npi}</Link></td>
                                <td><Link to={`/provider/${provider.providerID}`}>{provider.fullName}</Link></td>
                                <td><Link to={`/provider/${provider.providerID}`}>{provider.department}</Link></td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    )
}

export default ReportDetails;